import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape, FormattedDate } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  FieldTextInput,
  Form,
  FieldCurrencyInput,
  PrimaryButton,
  FieldDateInput,
  InfoIcon,
  IconEnquiry,
} from '../../components';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';

import css from './CustomOfferAcceptedForm.css';
import config from '../../config';
import { composeValidators, required } from '../../util/validators';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const BLUR_TIMEOUT_MS = 100;
const VAT_FACTOR = 125.5;
const FREEDOMLY_COMMISSION = 0.85;

class CustomOfferAcceptedFormComponent extends Component {
  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.blurTimeoutId = null;
  }

  handleFocus() {
    this.props.onFocus();
    window.clearTimeout(this.blurTimeoutId);
  }

  handleBlur() {
    // We only trigger a blur if another focus event doesn't come
    // within a timeout. This enables keeping the focus synced when
    // focus is switched between the message area and the submit
    // button.
    this.blurTimeoutId = window.setTimeout(() => {
      this.props.onBlur();
    }, BLUR_TIMEOUT_MS);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            isProvider,
            customOfferAmount,
            customOfferPlusVATAmount,
            customOfferVATAmount,
            customOfferDescription,
            customOfferDeliveryDate,
            handleSubmit,
            inProgress,
            customOfferError,
            invalid,
            form,
            formId,
            intl,
            values,
            isProOrInvoiceCustomer,
            showCustomOfferFormAcceptedProvider,
          } = formRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const freelancerWillReceive = new Money(
            Math.round(customOfferAmount.amount * FREEDOMLY_COMMISSION),
            'EUR'
          );
          const freedomlyCommission = new Money(
            Math.round(customOfferAmount.amount - freelancerWillReceive.amount),
            'EUR'
          );
          const modalProMessage = intl.formatMessage({
            id: 'TransactionPanel.acceptProModalMessage',
          });
          const modalProProviderMessage = (
            <FormattedMessage
              id="TransactionPanel.acceptProProviderModalMessage"
              values={{
                a: chunks => (
                  <a
                    href="https://help.freedomly.io/fi/articles/8728693-asiakas-ei-voi-maksaa-pankki-tai-luottokortilla-nain-onnistuu-laskutus"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#1683A3', fontWeight: 600 }}
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          );

          return (
            <Form className={classes} onSubmit={values => handleSubmit(values, form)}>
              <div className={css.customOfferDescription}>
                <FormattedMessage id="CustomOfferForm.customOfferDescription" />
              </div>

              <div className={css.deliveryContainer}>
                <FormattedMessage id="CustomOfferForm.deliveryDate" />
                <FormattedDate value={customOfferDeliveryDate} />
              </div>
              <div className={css.submitContainer}>
                <div className={css.customOfferPrice}>
                  <FormattedMessage id="CustomOfferForm.priceLabel" />
                  &nbsp;
                  {customOfferAmount ? formatMoney(intl, customOfferPlusVATAmount) : null}
                </div>
                <div className={css.customOfferVAT}>
                  <FormattedMessage id="CustomOfferForm.bidAmountVAT" />
                  &nbsp;
                  {customOfferAmount ? formatMoney(intl, customOfferVATAmount) : null}
                </div>
                <div className={css.customOfferService}>
                  <FormattedMessage id="CustomOfferForm.serviceLabel" />
                  &nbsp;
                  <div className={css.customOfferServiceAmount}>
                    {customOfferAmount ? formatMoney(intl, customOfferAmount) : null}
                  </div>
                </div>
                {isProvider ? (
                  <>
                    <div className={css.customOfferService}>
                      <FormattedMessage id="CustomOfferForm.freedomlyCommission" />
                      &nbsp;
                      <div className={css.customOfferServiceAmount}>
                        {freedomlyCommission ? formatMoney(intl, freedomlyCommission) : null}
                      </div>
                    </div>

                    <div className={css.customOfferService}>
                      <FormattedMessage id="CustomOfferForm.freelancerWillReceiveLabel2" />
                      &nbsp;
                      <div className={css.customOfferServiceAmount}>
                        {freelancerWillReceive ? formatMoney(intl, freelancerWillReceive) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                <div className={css.customOfferDescriptionContent}>{customOfferDescription}</div>

                <div className={css.errorContainer}>
                  {customOfferError ? (
                    <p className={css.error}>
                      <FormattedMessage id="CustomOfferForm.operationFailed" />
                    </p>
                  ) : null}
                </div>
                {/* {isProOrInvoiceCustomer && !isProvider ? (
                  <div className={css.customOfferDescriptionPro}>{modalProMessage}</div>
                ) : null} */}
                {isProOrInvoiceCustomer && isProvider && showCustomOfferFormAcceptedProvider ? (
                  <div className={css.customOfferDescriptionPro}>{modalProProviderMessage}</div>
                ) : null}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

CustomOfferAcceptedFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  messagePlaceholder: null,
  onFocus: () => null,
  onBlur: () => null,
  sendMessageError: null,
};

CustomOfferAcceptedFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,

  messagePlaceholder: string,
  onSubmit: func.isRequired,
  onFocus: func,
  onBlur: func,
  sendMessageError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CustomOfferAcceptedForm = compose(injectIntl)(CustomOfferAcceptedFormComponent);

CustomOfferAcceptedForm.displayName = 'CustomOfferAcceptedForm';

export default CustomOfferAcceptedForm;
