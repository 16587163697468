import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { injectIntl } from '../../util/reactIntl';
import { Form, SecondaryButton, Button, LinkedInAuthButton } from '../../components';
import { getLinkedInToken } from '../../util/api';

import css from './SignupProviderForms.css';
import { use } from 'passport';

const SignupProviderLinkedInForm = props => {
  const [dataFetchInProgress, setDataFetchInProgress] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Extract code and state from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      const redirect_uri = encodeURIComponent('signup/provider');

      // Retrieve and remove the original state from sessionStorage
      const originalState = sessionStorage.getItem('linkedin_oauth_state');
      sessionStorage.removeItem('linkedin_oauth_state');

      // Verify state to protect against CSRF attacks
      if (state !== originalState) {
        console.error('State mismatch! Potential CSRF attack.');
        return;
      }

      // Send the code to the server to exchange for an access token
      if (code) {
        setDataFetchInProgress(true);
        getLinkedInToken(code, redirect_uri)
          .then(data => {
            // if (data.error) {
            //   console.error('Error exchanging code:', data.error);
            // }
            sessionStorage.setItem('linkedin_data', JSON.stringify(data.linkedInData));
          })
          .then(() => {
            setDataFetchInProgress(false);
            setDataFetched(true);
          })
          .catch(error => {
            setError(error);
            setDataFetchInProgress(false);
            console.error('Error exchanging code:', error);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('linkedin_data')) {
      setDataFetched(true);
    }
  }, []);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          onPrev,
          rootClassName,
          className,
          handleSubmit,
          form,
          intl,
          invalid,
          isModal,
        } = formRenderProps;

        const submitDisabled = invalid || dataFetchInProgress;

        const classes = classNames(rootClassName || css.root, className);

        const heading = intl.formatMessage({ id: 'SignupProviderForms.linkedInHeading' });
        const point1 = intl.formatMessage({ id: 'SignupProviderForms.linkedInPoint1' });
        const point2 = intl.formatMessage({ id: 'SignupProviderForms.linkedInPoint2' });
        const point3 = intl.formatMessage({ id: 'SignupProviderForms.linkedInPoint3' });

        const nextButtonText = dataFetched
          ? intl.formatMessage({
              id: 'SignupProviderForms.nextButton',
            })
          : intl.formatMessage({ id: 'SignupProviderForms.skipButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const successMessage = intl.formatMessage({ id: 'SignupProviderForms.linkedInSuccess' });
        const linkedInErrorMessage = intl.formatMessage({
          id: 'SignupProviderForms.linkedInError',
        });

        return (
          <div className={classes}>
            <h2 className={css.mainHeading}>{heading}</h2>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <ul className={css.pointerList}>
                <li>{point1}</li>
                <li>{point2}</li>
                <li>{point3}</li>
              </ul>

              {dataFetched ? (
                <div className={css.linkedInSuccessMessage}>
                  <p>{successMessage}</p>
                </div>
              ) : error ? (
                <div className={css.linkedInErrorMessage}>
                  <p>{linkedInErrorMessage}</p>
                </div>
              ) : (
                <LinkedInAuthButton redirectUri={'signup/provider'} />
              )}
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(SignupProviderLinkedInForm);
