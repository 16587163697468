import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ExternalLink,
  SectionCustomerLogos,
  SectionHowItWorks,
  FaqDropdown,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import { IoIosArrowDown } from 'react-icons/io';

import SolutionPageHeroSection from './SolutionPageHeroSection';
import SolutionPageCustomerReviews from './SolutionPageCustomerReviews';

import heroImage from '../../assets/marketing-freelancer-hero-image.webp';
import miikkaImg from '../../assets/pro-miikka-salminen.webp';

import css from './SolutionPages.css';

const MarketingSolutionPageComponent = props => {
  const { intl, scrollingDisabled } = props;

  const schemaTitle = intl.formatMessage({ id: 'MarketingSolutionPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'MarketingSolutionPage.schemaDescription',
  });

  const heroTitle = (
    <FormattedMessage
      id="MarketingSolutionPage.heroTitle"
      values={{
        b: chunks => <strong style={{ color: '#1683A3', fontWeight: 600 }}>{chunks}</strong>,
      }}
    />
  );
  const heroSubtitle = intl.formatMessage({ id: 'MarketingSolutionPage.heroSubtitle' });
  const heroButtonText = intl.formatMessage({ id: 'MarketingSolutionPage.heroButtonText' });

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  const HowItWorksCard = ({ title, description, checkmarkText }) => {
    return (
      <div className={css.howItWorksCard}>
        <div>
          <div className={css.howItWorksCardTitle}>{title}</div>
          <div className={css.howItWorksCardDescription}>{description}</div>
        </div>
        <div className={css.howItWorksCardCheckmarkContainer}>
          <BsFillCheckCircleFill className={css.howItWorksCardCheckmark} />
          {checkmarkText}
        </div>
      </div>
    );
  };

  const sectionHowItWorksHeader = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksHeader" />
  );
  const sectionHowItWorksCardTitle1 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardTitle1" />
  );
  const sectionHowItWorksCardTitle2 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardTitle2" />
  );
  const sectionHowItWorksCardTitle3 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardTitle3" />
  );
  const sectionHowItWorksCardDescription1 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardDescription1" />
  );
  const sectionHowItWorksCardDescription2 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardDescription2" />
  );
  const sectionHowItWorksCardDescription3 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCardDescription3" />
  );
  const sectionHowItWorksInfoImageName = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksInfoImageName" />
  );
  const sectionHowItWorksInfoImageText1 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksInfoImageText1" />
  );
  const sectionHowItWorksInfoImageText2 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksInfoImageText2" />
  );
  const sectionHowItWorksInfoImageText3 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksInfoImageText3" />
  );
  const sectionHowItWorksInfoImageText4 = (
    <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksInfoImageText4" />
  );
  const sectionHowItWorksCTA = <FormattedMessage id="MarketingSolutionPage.sectionHowItWorksCTA" />;

  const howItWorksCardTitle1 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle1" />;
  const howItWorksCardText1 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText1" />;
  const howItWorksCardCheckmarkText1 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText1" />
  );
  const howItWorksCardTitle2 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle2" />;
  const howItWorksCardText2 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText2" />;
  const howItWorksCardCheckmarkText2 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText2" />
  );
  const howItWorksCardTitle3 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle3" />;
  const howItWorksCardText3 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText3" />;
  const howItWorksCardCheckmarkText3 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText3" />
  );

  const [faqIsShown, setFaqIsShown] = useState([
    {
      question: <FormattedMessage id="MarketingSolutionPage.sectionFAQQuestion1" />,
      answer: (
        <FormattedMessage
          id="MarketingSolutionPage.sectionFAQAnswer1"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 0,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="MarketingSolutionPage.sectionFAQQuestion2" />,
      answer: (
        <FormattedMessage
          id="MarketingSolutionPage.sectionFAQAnswer2"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 1,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="MarketingSolutionPage.sectionFAQQuestion3" />,
      answer: (
        <FormattedMessage
          id="MarketingSolutionPage.sectionFAQAnswer3"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 2,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
    {
      question: <FormattedMessage id="MarketingSolutionPage.sectionFAQQuestion4" />,
      answer: (
        <FormattedMessage
          id="MarketingSolutionPage.sectionFAQAnswer4"
          values={{
            lineBreak: <br />,
          }}
        />
      ),
      index: 3,
      isShow: false,
      arrow: <IoIosArrowDown />,
    },
  ]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="MarketingSolutionPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.sections}>
            <SolutionPageHeroSection
              title={heroTitle}
              subTitle={heroSubtitle}
              buttonText={heroButtonText}
              imgSrc={heroImage}
              handleShowSpaceHome={handleShowSpaceHome}
            />
            <SectionCustomerLogos
              toShow={[
                'peikko',
                'olefit',
                'eerikkila',
                'corego',
                'mobal',
                'strongest',
                'systam',
                'zervant',
              ]}
            />
            <SectionHowItWorks
              header={sectionHowItWorksHeader}
              cardTitle1={sectionHowItWorksCardTitle1}
              cardTitle2={sectionHowItWorksCardTitle2}
              cardTitle3={sectionHowItWorksCardTitle3}
              cardDescription1={sectionHowItWorksCardDescription1}
              cardDescription2={sectionHowItWorksCardDescription2}
              cardDescription3={sectionHowItWorksCardDescription3}
              infoImageName={sectionHowItWorksInfoImageName}
              infoImageText1={sectionHowItWorksInfoImageText1}
              infoImageText2={sectionHowItWorksInfoImageText2}
              infoImageText3={sectionHowItWorksInfoImageText3}
              infoImageText4={sectionHowItWorksInfoImageText4}
              cta={sectionHowItWorksCTA}
              atLandingPage={true}
            />
            <SolutionPageCustomerReviews atLandingPage={false} />
            <div className={css.sectionWhyFreedomly}>
              <div className={css.sectionWhyFreedomlyTitle}>
                <FormattedMessage id="MarketingSolutionPage.sectionWhyFreedomlyTitle" />
              </div>
              <div className={css.sectionWhyFreedomlyContent}>
                <div className={css.whyFreedomlyCard}>
                  <div className={css.whyFreedomlyCardTitle}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardTitle1" />
                  </div>
                  <div className={css.whyFreedomlyCardText}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardText1" />
                  </div>
                  <div className={css.whyFreedomlyCardBulletPoints}>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint11" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint12" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint13" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint14" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint15" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint16" />
                    </div>
                  </div>
                </div>
                <div className={css.whyFreedomlyCard}>
                  <div className={css.whyFreedomlyCardTitle}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardTitle2" />
                  </div>
                  <div className={css.whyFreedomlyCardText}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardText2" />
                  </div>
                  <div className={css.whyFreedomlyCardBulletPoints}>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint21" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint22" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint23" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint24" />
                    </div>
                  </div>
                </div>
                <div className={css.whyFreedomlyCard}>
                  <div className={css.whyFreedomlyCardTitle}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardTitle3" />
                  </div>
                  <div className={css.whyFreedomlyCardText}>
                    <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardText3" />
                  </div>
                  <div className={css.whyFreedomlyCardBulletPoints}>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint31" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint32" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint33" />
                    </div>
                    <div className={css.whyFreedomlyCardBulletPoint}>
                      <BsFillCheckCircleFill className={css.whyFreedomlyCardCheckmark} />
                      <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCardBulletPoint34" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={css.whyFreedomlyCTA}>
                <NamedLink className={css.whyFreedomlyCTAButton} name="SignupPage">
                  <FormattedMessage id="MarketingSolutionPage.whyFreedomlyCTA" />
                </NamedLink>
              </div>
            </div>
            <div className={css.sectionFAQContent}>
              <div className={css.sectionFAQTitle}>
                <FormattedMessage id="MarketingSolutionPage.sectionFAQTitle" />
              </div>
              <FaqDropdown faqIsShown={faqIsShown} setFaqIsShown={setFaqIsShown} />
            </div>
            <div className={css.sectionHowItWorksContent}>
              <div className={css.howItWorksTitle}>
                <FormattedMessage id="StartSellingInfoPage.howItWorksTitle" />
              </div>
              <div className={css.howItWorksCardContainer}>
                <HowItWorksCard
                  title={howItWorksCardTitle1}
                  description={howItWorksCardText1}
                  checkmarkText={howItWorksCardCheckmarkText1}
                />
                <HowItWorksCard
                  title={howItWorksCardTitle2}
                  description={howItWorksCardText2}
                  checkmarkText={howItWorksCardCheckmarkText2}
                />
                <HowItWorksCard
                  title={howItWorksCardTitle3}
                  description={howItWorksCardText3}
                  checkmarkText={howItWorksCardCheckmarkText3}
                />
              </div>
              <div className={css.howItWorksCTA}>
                <NamedLink className={css.howItWorksCTAButton} name="SignupPage">
                  <FormattedMessage id="OrganizationSolutionPage.howItWorksCTA" />
                </NamedLink>
              </div>
            </div>
          </div>
          <div className={css.contactSection}>
            <div className={css.contactSectionContactCard}>
              <div className={css.contactCard}>
                <div className={css.contactCardAvatar}>
                  <img src={miikkaImg} alt="Contact" />
                </div>
                <div className={css.contactCardName}>Miikka Salminen, CEO</div>
                <div className={css.contactCardInformation}>miikka@freedomly.io</div>
                <div className={css.contactCardInformation}>+358 45 268 2218</div>
                <div>
                  <ExternalLink
                    className={css.contactSectionContactButton}
                    onClick={handleShowSpaceHome}
                  >
                    <FormattedMessage id="MarketingSolutionPage.heroContactButton" />
                  </ExternalLink>
                </div>
              </div>
            </div>
            <div className={css.contactSectionTextContent}>
              <div className={css.contactSectionTitle}>
                <FormattedMessage id="MarketingSolutionPage.contactSectionTitle" />
              </div>
              <div className={css.contactSectionContentText}>
                <FormattedMessage id="MarketingSolutionPage.contactSectionText" />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { number, shape } = PropTypes;

MarketingSolutionPageComponent.propTypes = {
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired, // from injectIntl
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const MarketingSolutionPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(MarketingSolutionPageComponent);

export default MarketingSolutionPage;
