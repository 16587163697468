import React from 'react';
import { ProfileSettingsContainer as Container } from '..';
import { injectIntl } from '../../util/reactIntl';
import css from './Container.css';

const NameLocationWorkingMethodContainer = ({
  profileUser,
  button,
  intl,
  isValidCustomer,
  isValidProvider,
}) => {
  const accountRole = profileUser?.attributes?.profile?.publicData?.account_role;
  const firstName = profileUser?.attributes?.profile?.firstName;
  const lastName = profileUser?.attributes?.profile?.lastName;
  const locationTemp = profileUser?.attributes?.profile?.publicData?.location;
  const industry = profileUser?.attributes?.profile?.protectedData?.industry?.label;
  const noIndexing = profileUser?.attributes?.profile?.publicData?.noIndexing;

  const location = locationTemp !== undefined ? locationTemp.search : null;

  const workingMethods = [
    {
      key: 'remoteNInPerson',
      label: intl.formatMessage({ id: 'WorkingMethods.remoteNInPerson' }),
    },
    {
      key: 'remote',
      label: intl.formatMessage({ id: 'WorkingMethods.remote' }),
    },
  ];

  const workingMethodKey = profileUser?.attributes?.profile?.publicData?.workingMethod;

  const workingMethod = workingMethods.find(m => m.key === workingMethodKey);
  const workingMethodLabel = workingMethod ? workingMethod.label : null;

  const locationHeading = intl.formatMessage({
    id: 'ProfileSettingsForm.locationHeading',
  });
  const industryHeading = intl.formatMessage({
    id: 'ProfileSettingsForm.industryHeading',
  });
  const workingMethodHeading = intl.formatMessage({
    id: 'ProfileSettingsForm.workingMethodHeading',
  });
  const noIndexingHeading = intl.formatMessage({
    id: 'ProfileSettingsForm.noIndexingHeading',
  });
  const indexingHeading = intl.formatMessage({
    id: 'ProfileSettingsForm.indexingHeading',
  });
  const container = (
    <Container heading={' '} button={button}>
      <div className={css.container}>
        <div className={css.heading}>
          {firstName} {lastName}
        </div>
        <div className={css.contentContainer}>
          {location ? (
            <div className={css.contentItem}>
              <div className={css.contentHeading}>{locationHeading}</div>
              <div className={css.text}>{location}</div>
            </div>
          ) : null}
          {industry && isValidCustomer ? (
            <div className={css.contentItem}>
              <div className={css.contentHeading}>{industry ? industryHeading : null}</div>
              <div className={css.text}>{industry}</div>
            </div>
          ) : null}
          {workingMethodLabel && isValidProvider ? (
            <div className={css.contentItem}>
              <div className={css.contentHeading}>{workingMethodHeading}</div>
              <div className={css.text}>{workingMethodLabel}</div>
            </div>
          ) : null}
          {!noIndexing ? (
            <div className={css.noIndexingHeading}>{indexingHeading}</div>
          ) : (
            <div className={css.noIndexingHeading}>{noIndexingHeading}</div>
          )}
        </div>
      </div>
    </Container>
  );

  if (!button) {
    return null;
  }
  return container;
};

export default injectIntl(NameLocationWorkingMethodContainer);
