import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton, NamedLink, Form, FieldTextInput } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import { CiWarning } from 'react-icons/ci';
import * as validators from '../../util/validators';

import css from './ApplicationPanel.css';
import { after, set } from 'lodash';

const AT_RE = /@/i;
//eslint-disable-next-line
const PH_NO_RE = /\s?(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}/;
const PH_NO_RE2 = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
const LINK_RE = /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/;

const ActionsComponent = props => {
  const [messageNotValid, setMessageNotValid] = React.useState(true);
  const [afterSkip, setAfterSkip] = React.useState(false);

  const handleSubmitLogic = values => {
    const enhancedValues = {
      ...values,
      afterSkip: afterSkip,
    };
    props.onSubmit(enhancedValues);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ setFieldData }}
      onSubmit={handleSubmitLogic}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          showHiringSteps,
          submitButtonWrapperClassName,
          formId,
          handleSubmit,
          inProgress,
          intl,
          listingTitle,
          authorDisplayName,
          sendEnquiryError,
          currentUser,
          listing,
          values,
          transaction,
          transactionId,
          showActionButtons,
          showAcceptAfterSkipButton,
          sendAcceptApplicationInProgress,
          showProfileListingInProgress,
          sendCreateReverseTransactionInProgress,
          sendSkipApplicationInProgress,
          sendAcceptAfterSkipInProgress,
          handleAcceptApplication,
          onSkipApplication,
          handleAcceptApplicationAfterSkip,
          showToNewTransactionButton,
          isCustomer,
        } = fieldRenderProps;

        const buttonsDisabled =
          sendAcceptApplicationInProgress ||
          showProfileListingInProgress ||
          sendCreateReverseTransactionInProgress ||
          sendSkipApplicationInProgress ||
          sendAcceptAfterSkipInProgress;

        const newTransactionId = transaction.attributes.protectedData.newTransactionId;

        const messageValidation = value => {
          if (value && AT_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && LINK_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && PH_NO_RE.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && PH_NO_RE2.test(value)) {
            setMessageNotValid(true);
            return true;
          }
          if (value && value === '') {
            setMessageNotValid(true);
            return true;
          }
          setMessageNotValid(false);
          return false;
        };

        const warningIcon = <CiWarning />;

        const messageLabel = intl.formatMessage(
          {
            id: 'EnquiryForm.messageLabel',
          },
          { authorDisplayName }
        );
        const messagePlaceholder = intl.formatMessage(
          {
            id: 'ApplicationPanel.messagePlaceholder',
          },
          { authorDisplayName }
        );
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });

        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        const contactInformationErrorMessage = intl.formatMessage({
          id: 'EnquiryForm.contactInformationErrorMessage',
        });

        const submitDisabled = messageNotValid;

        return (
          <Form className={css.form} onSubmit={handleSubmit}>
            {showActionButtons && (
              <div className={css.formWrapper}>
                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  name="message"
                  id={formId ? `${formId}.message` : 'message'}
                  placeholder={messagePlaceholder}
                  validate={messageRequired}
                  warningMessage={contactInformationErrorMessage}
                  showWarning={messageValidation(values.message)}
                  icon={warningIcon}
                />
                <PrimaryButton
                  className={css.submitButton}
                  inProgress={
                    sendAcceptApplicationInProgress ||
                    showProfileListingInProgress ||
                    sendCreateReverseTransactionInProgress
                  }
                  disabled={submitDisabled}
                  type="submit"
                  onClick={() => {
                    setAfterSkip(false);
                  }}
                >
                  <FormattedMessage id="ApplicationPanel.startConversation" />
                </PrimaryButton>
                <p className={css.skipDescription}>
                  <FormattedMessage id="ApplicationPanel.skipDescription" />
                </p>
                <SecondaryButton
                  className={css.actionButton}
                  inProgress={sendSkipApplicationInProgress}
                  disabled={buttonsDisabled}
                  onClick={() => onSkipApplication(transactionId)}
                >
                  <FormattedMessage id="ApplicationPanel.freezeApplication" />
                </SecondaryButton>
              </div>
            )}
            {showAcceptAfterSkipButton && (
              <div className={css.formWrapper}>
                <FieldTextInput
                  className={css.field}
                  type="textarea"
                  name="message"
                  id={formId ? `${formId}.message` : 'message'}
                  placeholder={messagePlaceholder}
                  validate={messageRequired}
                  warningMessage={contactInformationErrorMessage}
                  showWarning={messageValidation(values.message)}
                  icon={warningIcon}
                />
                <PrimaryButton
                  className={css.submitButton}
                  inProgress={
                    sendAcceptApplicationInProgress ||
                    showProfileListingInProgress ||
                    sendCreateReverseTransactionInProgress
                  }
                  disabled={buttonsDisabled}
                  type="submit"
                  onClick={() => {
                    setAfterSkip(true);
                  }}
                >
                  <FormattedMessage id="ApplicationPanel.startConversation" />
                </PrimaryButton>
              </div>
            )}
            {showToNewTransactionButton && (
              <div className={css.toNewProjectWrapper}>
                <NamedLink
                  className={css.toNewTransactionButton}
                  name={isCustomer ? 'SaleDetailsPage' : 'OrderDetailsPage'}
                  params={{ id: newTransactionId }}
                >
                  <FormattedMessage id="ApplicationPanel.toNewTransaction" />
                </NamedLink>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

const Actions = compose(injectIntl)(ActionsComponent);

export default Actions;
