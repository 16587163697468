import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_PROJECT_OFFERS_REQUEST = 'app/InboxPage/FETCH_PROJECT_OFFERS_REQUEST';
export const FETCH_PROJECT_OFFERS_SUCCESS = 'app/InboxPage/FETCH_PROJECT_OFFERS_SUCCESS';
export const FETCH_PROJECT_OFFERS_ERROR = 'app/InboxPage/FETCH_PROJECT_OFFERS_ERROR';
export const FETCH_PROJECT_OFFERS_RESET = 'app/InboxPage/FETCH_PROJECT_OFFERS_RESET';
export const FILTER_TRANSACTIONS = 'app/InboxPage/FILTER_TRANSACTIONS';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  fetchProjectOffersInProgress: false,
  fetchProjectOffersError: null,
  pagination: null,
  transactionRefs: [],
  projectTransactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_PROJECT_OFFERS_REQUEST:
      return { ...state, fetchProjectOffersInProgress: true, fetchProjectOffersError: null };
    case FETCH_PROJECT_OFFERS_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchProjectOffersInProgress: false,
        projectTransactionRefs: entityRefs(transactions),
      };
    }
    case FETCH_PROJECT_OFFERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchProjectOffersInProgress: false, fetchProjectOffersError: payload };
    case FETCH_PROJECT_OFFERS_RESET:
      return {
        ...state,
        fetchProjectOffersInProgress: false,
        fetchProjectOffersError: null,
        projectTransactionRefs: [],
      };

    case FILTER_TRANSACTIONS:
      // const filteredTxs = state.transactionRefs.filter(tx => {
      //   return action.filters.includes(tx.)
      // })
      return state;

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const filterTransactions = filters => {
  return {
    type: FILTER_TRANSACTIONS,
    filters: filters,
  };
};

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  dispatch(fetchCurrentUser());

  const lastTransitionsEnquiry = [
    'transition/enquire',
    'transition/make-custom-offer',
    'transition/make-custom-pro-offer',
    'transition/request-payment',
    'transition/request-payment-after-enquiry',
    'transition/expire-payment',
    'transition/make-project-bid',
    'transition/make-project-bid-after-enquiry',
    'transition/expire-offer',
    'transition/accept-offer',
  ];

  const lastTransitionsOngoing = [
    'transition/confirm-project',
    'transition/confirm-payment',
    'transition/accept',
    'transition/complete',
    'transition/accept-custom-pro-offer',
    'transition/job-done-pro',
  ];

  const lastTransitionsComplete = [
    'transition/release-funds',
    'transition/review-1-by-provider',
    'transition/review-1-by-customer',
    'transition/review-2-by-provider',
    'transition/review-2-by-customer',
    'transition/expire-review-period',
    'transition/expire-provider-review-period',
    'transition/expire-customer-review-period',
    'transition/job-accepted',
    'transition/skip-to-reviews',
  ];

  const lastTransitions =
    tab === 'orders' || tab === 'sales'
      ? TRANSITIONS
      : tab === 'enquiries' || tab === 'saleEnquiries'
      ? lastTransitionsEnquiry
      : tab === 'ongoing' || tab === 'saleOngoing'
      ? lastTransitionsOngoing
      : tab === 'completed' || tab === 'saleCompleted'
      ? lastTransitionsComplete
      : TRANSITIONS;

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    // only: onlyFilter,
    processNames: ['flex-default-process', 'flex-light-process', 'flex-pro-process'],
    lastTransitions: lastTransitions,
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
