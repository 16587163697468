import React from 'react';

import css from './SectionPortfolioLogos.css';
import { FormattedMessage } from 'react-intl';

import netflixLogo from '../../assets/netflix-logo.svg';
import microsoftLogo from '../../assets/microsoft-logo.svg';
import warnerMusicLogo from '../../assets/warner-music-logo.svg';
import tietoEvryLogo from '../../assets/tieto-evry-logo.svg';
import fedexLogo from '../../assets/fedex-express-logo.svg';
import samsungLogo from '../../assets/samsung-logo.svg';
import sapLogo from '../../assets/sap-logo.svg';
import zalandLogo from '../../assets/zalando-logo.svg';

const SectionPortfolioLogos = () => {
  return (
    <div className={css.container}>
      <div className={css.title}>
        <FormattedMessage id="SectionPortfolioLogos.title" />
      </div>
      <div className={css.logos}>
        <div className={css.logoContainer}>
          <img src={netflixLogo} alt="logo" className={css.logo} />
        </div>
        <div className={css.logoContainer}>
          <img src={warnerMusicLogo} alt="logo" className={css.logo} />
        </div>
        <div className={css.logoContainer}>
          <img src={zalandLogo} alt="logo" className={css.logo} />
        </div>
        <div className={css.logoContainer}>
          <img src={microsoftLogo} alt="logo" className={css.logo} />
        </div>
        <div className={css.logoContainer}>
          <img src={fedexLogo} alt="logo" className={css.logo} />
        </div>
        <div className={css.logoContainer}>
          <img src={samsungLogo} alt="logo" className={css.logo} />
        </div>
      </div>
    </div>
  );
};

export default SectionPortfolioLogos;
