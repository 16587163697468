import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

import { types as sdkTypes } from '../../util/sdkLoader';
import {
  markTxRead,
  markTxUnread,
  markApplicationNew,
  markApplicationOpened,
} from '../../util/api';
import {
  fetchCurrentUserNotifications,
  fetchCurrentUserHasOrdersSuccess,
  fetchCurrentUserApplications,
  fetchCurrentUserApplicationsSuccess,
} from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_ACCEPT_APPLICATION,
  TRANSITION_SKIP_APPLICATION,
  TRANSITION_ACCEPT_AFTER_SKIP,
} from '../../util/transaction';
import config from '../../config';

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

const { UUID } = sdkTypes;

// ================ Action types ================ //
export const SET_INITIAL_VALUES = 'app/ApplicationPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/ApplicationPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TApplicationPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/ApplicationPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/ApplicationPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/ApplicationPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/ApplicationPage/FETCH_TRANSITIONS_ERROR';

export const SEND_ACCEPT_APPLICATION_REQUEST =
  'app/ApplicationPage/SEND_ACCEPT_APPLICATION_REQUEST';
export const SEND_ACCEPT_APPLICATION_SUCCESS =
  'app/ApplicationPage/SEND_ACCEPT_APPLICATION_SUCCESS';
export const SEND_ACCEPT_APPLICATION_ERROR = 'app/ApplicationPage/SEND_ACCEPT_APPLICATION_ERROR';

export const SHOW_PROFILE_LISTING_REQUEST = 'app/ApplicationPage/SHOW_PROFILE_LISTING_REQUEST';
export const SHOW_PROFILE_LISTING_SUCCESS = 'app/ApplicationPage/SHOW_PROFILE_LISTING_SUCCESS';
export const SHOW_PROFILE_LISTING_ERROR = 'app/ApplicationPage/SHOW_PROFILE_LISTING_ERROR';

export const SEND_CREATE_REVERSE_TRANSACTION_REQUEST =
  'app/ApplicationPage/SEND_CREATE_REVERSE_TRANSACTION_REQUEST';
export const SEND_CREATE_REVERSE_TRANSACTION_SUCCESS =
  'app/ApplicationPage/SEND_CREATE_REVERSE_TRANSACTION_SUCCESS';
export const SEND_CREATE_REVERSE_TRANSACTION_ERROR =
  'app/ApplicationPage/SEND_CREATE_REVERSE_TRANSACTION_ERROR';

export const SEND_SKIP_APPLICATION_REQUEST = 'app/ApplicationPage/SEND_SKIP_APPLICATION_REQUEST';
export const SEND_SKIP_APPLICATION_SUCCESS = 'app/ApplicationPage/SEND_SKIP_APPLICATION_SUCCESS';
export const SEND_SKIP_APPLICATION_ERROR = 'app/ApplicationPage/SEND_SKIP_APPLICATION_ERROR';

export const SEND_ACCEPT_AFTER_SKIP_REQUEST = 'app/ApplicationPage/SEND_ACCEPT_AFTER_SKIP_REQUEST';
export const SEND_ACCEPT_AFTER_SKIP_SUCCESS = 'app/ApplicationPage/SEND_ACCEPT_AFTER_SKIP_SUCCESS';
export const SEND_ACCEPT_AFTER_SKIP_ERROR = 'app/ApplicationPage/SEND_ACCEPT_AFTER_SKIP_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  sendAcceptApplicationInProgress: false,
  sendAcceptApplicationError: null,
  profileListing: null,
  showProfileListingInProgress: false,
  showProfileListingError: null,
  sendCreateReverseTransactionInProgress: false,
  sendCreateReverseTransactionError: null,
  sendSkipApplicationInProgress: false,
  sendSkipApplicationError: null,
  sendAcceptAfterSkipInProgress: false,
  sendAcceptAfterSkipError: null,
};

export default function applicationPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case SEND_ACCEPT_APPLICATION_REQUEST:
      return { ...state, sendAcceptApplicationInProgress: true, sendAcceptApplicationError: null };
    case SEND_ACCEPT_APPLICATION_SUCCESS:
      return { ...state, sendAcceptApplicationInProgress: false };
    case SEND_ACCEPT_APPLICATION_ERROR:
      return {
        ...state,
        sendAcceptApplicationInProgress: false,
        sendAcceptApplicationError: payload,
      };

    case SHOW_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        showProfileListingInProgress: true,
        showProfileListingError: null,
        profileListing: null,
      };
    case SHOW_PROFILE_LISTING_SUCCESS:
      return { ...state, showProfileListingInProgress: false, profileListing: payload };
    case SHOW_PROFILE_LISTING_ERROR:
      return { ...state, showProfileListingInProgress: false, showProfileListingError: payload };

    case SEND_CREATE_REVERSE_TRANSACTION_REQUEST:
      return {
        ...state,
        sendCreateReverseTransactionInprogress: true,
        sendCreateReverseTransactionError: null,
      };
    case SEND_CREATE_REVERSE_TRANSACTION_SUCCESS:
      return { ...state, sendCreateReverseTransactionInProgress: false };
    case SEND_CREATE_REVERSE_TRANSACTION_ERROR:
      return {
        ...state,
        sendCreateReverseTransactionInProgress: false,
        sendCreateReverseTransactionError: payload,
      };

    case SEND_SKIP_APPLICATION_REQUEST:
      return {
        ...state,
        sendSkipApplicationInProgress: true,
        sendSkipApplicationError: null,
      };
    case SEND_SKIP_APPLICATION_SUCCESS:
      return { ...state, sendSkipApplicationInProgress: false };
    case SEND_SKIP_APPLICATION_ERROR:
      return {
        ...state,
        sendSkipApplicationInProgress: false,
        sendSkipApplicationError: payload,
      };

    case SEND_ACCEPT_AFTER_SKIP_REQUEST:
      return {
        ...state,
        sendAcceptAfterSkipInProgress: true,
        sendAcceptAfterSkipError: null,
      };
    case SEND_ACCEPT_AFTER_SKIP_SUCCESS:
      return { ...state, sendAcceptAfterSkipInProgress: false };
    case SEND_ACCEPT_AFTER_SKIP_ERROR:
      return {
        ...state,
        sendAcceptAfterSkipInProgress: false,
        sendAcceptAfterSkipError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

export const sendAcceptApplicationRequest = () => ({ type: SEND_ACCEPT_APPLICATION_REQUEST });
export const sendAcceptApplicationSuccess = () => ({ type: SEND_ACCEPT_APPLICATION_SUCCESS });
export const sendAcceptApplicationError = e => ({
  type: SEND_ACCEPT_APPLICATION_ERROR,
  error: true,
  payload: e,
});

const showProfileListingRequest = () => ({ type: SHOW_PROFILE_LISTING_REQUEST });
const showProfileListingSuccess = pl => ({ type: SHOW_PROFILE_LISTING_SUCCESS, payload: pl });
const showProfileListingError = e => ({
  type: SHOW_PROFILE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendCreateReverseTransactionRequest = () => ({
  type: SEND_CREATE_REVERSE_TRANSACTION_REQUEST,
});
export const sendCreateReverseTransactionSuccess = () => ({
  type: SEND_CREATE_REVERSE_TRANSACTION_SUCCESS,
});
export const sendCreateReverseTransactionError = e => ({
  type: SEND_CREATE_REVERSE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const sendSkipApplicationRequest = () => ({
  type: SEND_SKIP_APPLICATION_REQUEST,
});
export const sendSkipApplicationSuccess = () => ({
  type: SEND_SKIP_APPLICATION_SUCCESS,
});
export const sendSkipApplicationError = e => ({
  type: SEND_SKIP_APPLICATION_ERROR,
  error: true,
  payload: e,
});

export const sendAcceptAfterSkipRequest = () => ({
  type: SEND_ACCEPT_AFTER_SKIP_REQUEST,
});
export const sendAcceptAfterSkipSuccess = () => ({
  type: SEND_ACCEPT_AFTER_SKIP_SUCCESS,
});
export const sendAcceptAfterSkipError = e => ({
  type: SEND_ACCEPT_AFTER_SKIP_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //
const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

export const acceptAfterSkip = (id, transactionId) => (dispatch, getState, sdk) => {
  dispatch(sendAcceptAfterSkipRequest());

  return sdk.transactions
    .transition(
      {
        id,
        transition: TRANSITION_ACCEPT_AFTER_SKIP,
        params: { protectedData: { newTransactionId: transactionId.uuid } },
      },
      { expand: true, include: ['customer'] }
    )
    .then(async response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;

      await markApplicationNew({
        transactionId: transactionId.uuid,
        recipientId: customer.id.uuid,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(sendAcceptAfterSkipSuccess());
    })
    .catch(e => {
      dispatch(sendAcceptAfterSkipError(storableError(e)));
      throw e;
    });
};

export const skipApplication = id => (dispatch, getState, sdk) => {
  dispatch(sendSkipApplicationRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_SKIP_APPLICATION, params: {} },
      { expand: true, include: ['customer'] }
    )
    .then(async response => {
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const customer = transaction.relationships.customer.data;

      await markApplicationNew({
        transactionId: transactionId.uuid,
        recipientId: customer.id.uuid,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(sendSkipApplicationSuccess());
    })
    .catch(e => {
      dispatch(sendSkipApplicationError(storableError(e)));
      throw e;
    });
};

export const acceptApplication = (id, transactionId, afterSkip) => (dispatch, getState, sdk) => {
  dispatch(sendAcceptApplicationRequest());
  return sdk.transactions
    .transition(
      {
        id,
        transition: !afterSkip ? TRANSITION_ACCEPT_APPLICATION : TRANSITION_ACCEPT_AFTER_SKIP,
        params: { protectedData: { newTransactionId: transactionId.uuid } },
      },
      { expand: true, include: ['customer'] }
    )
    .then(async response => {
      const transaction = response.data.data;

      await markApplicationNew({
        transactionId: transaction.id.uuid,
        recipientId: transaction.relationships.customer.data.id.uuid,
      });
      dispatch(addMarketplaceEntities(response));
      dispatch(sendAcceptApplicationSuccess());

      return transactionId;
    })
    .catch(e => {
      dispatch(sendAcceptApplicationError(storableError(e)));
      throw e;
    });
};

export const createReverseTransaction = (
  listingId,
  protectedData,
  isLightEntrepreneur,
  isProProcess,
  message
) => (dispatch, getState, sdk) => {
  dispatch(sendCreateReverseTransactionRequest());

  const processAlias = isProProcess
    ? config.proProcessAlias
    : isLightEntrepreneur
    ? config.lightProcessAlias
    : config.bookingProcessAlias;

  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: processAlias,

    params: {
      listingId,
      protectedData,
    },
  };
  return sdk.transactions
    .initiate(bodyParams, { expand: true, include: ['provider'] })
    .then(response => {
      dispatch(sendCreateReverseTransactionSuccess());
      const transaction = response.data.data;
      const transactionId = transaction.id;
      const provider = transaction.relationships.provider.data;
      const recipientId = provider.id;

      return sdk.messages.send({ transactionId, content: message }, { expand: true }).then(res => {
        const messageData = res.data.data;
        // Send the message to the created transaction
        return markTxUnread({
          transactionId: transactionId.uuid,
          recipientId: recipientId.uuid,
          message: messageData,
        }).then(() => {
          dispatch(sendCreateReverseTransactionSuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return transactionId;
        });
      });

      // return transactionId;
    })

    .catch(e => {
      console.log('error', e);
      dispatch(sendCreateReverseTransactionError(storableError(e)));
      throw e;
    });
};

export const showProfileListing = userId => (dispatch, getState, sdk) => {
  dispatch(showProfileListingRequest());

  return sdk.listings
    .query({
      authorId: userId,
      pub_listingType: 'profile',
      include: ['author'],
    })
    .then(response => {
      const listings = response.data.data || [];

      if (listings.length >= 1) {
        dispatch(showProfileListingSuccess(listings[0]));
      } else {
        throw new Error('Provider does not have a profile listing created.');
      }

      return denormalisedResponseEntities(response)[0];
    })
    .catch(e => {
      dispatch(showProfileListingError(storableError(e)));
      console.error(e);
    });
};

export const fetchTransaction = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];

      const processName = response.data.data.attributes.processName;

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;

      // prevent default & light process transaction access to the page
      if (processName !== 'flex-project-process') {
        throw new Error('Wrong process');
      }

      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const { currentUser } = getState().user;
  const currentUserId = currentUser && currentUser.id ? currentUser.id.uuid : '0';
  const state = getState().ApplicationPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  return markApplicationOpened(params.id, currentUserId).then(() => {
    // Sale / order (i.e. transaction entity in API)
    return Promise.all([
      dispatch(fetchCurrentUserNotifications()),
      dispatch(fetchCurrentUserApplications()),
      dispatch(fetchTransaction(txId)),
      dispatch(fetchNextTransitions(txId)),
    ]);
  });
};
