import React from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import {
  Page,
  SectionLandingPageHero,
  SectionHowItWorks,
  SectionLocations,
  SectionPortfolioLogos,
  SectionFreedomlyStats,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  NamedLink,
  ExternalLink,
  SuccessStoryBanner,
} from '../../components';
import SolutionPageCustomerReviews from '../SolutionLandingPages/SolutionPageCustomerReviews';
import { TopbarContainer } from '../../containers';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import facebookImage from '../../assets/freedomlySocialShare.png';
import twitterImage from '../../assets/freedomlySocialShare.png';
import succesStoryBannerImage from '../../assets/systam-success-story-quote2.webp';
import miikkaImg from '../../assets/pro-miikka-salminen.webp';

import css from './LandingPage.css';
import { propTypes } from '../../util/types';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUser,
    currentUserFetchInProgress,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const heroTitle1 = <FormattedMessage id="SectionHero.title" />;
  const heroTitle2 = <FormattedMessage id="SectionHero.title2" />;
  const heroSubtitle = <FormattedMessage id="SectionHero.subTitle" />;

  const sectionHowItWorksHeader = <FormattedMessage id="SectionHowItWorks.title" />;
  const sectionHowItWorksCardTitle1 = <FormattedMessage id="SectionHowItWorks.cardTitle1" />;
  const sectionHowItWorksCardTitle2 = <FormattedMessage id="SectionHowItWorks.cardTitle2" />;
  const sectionHowItWorksCardTitle3 = <FormattedMessage id="SectionHowItWorks.cardTitle3" />;
  const sectionHowItWorksCTA = <FormattedMessage id="SectionHowItWorks.subTitle" />;

  const sectionHowItWorksCardDescription1 = (
    <FormattedMessage id="SectionHowItWorks.cardDescription1" />
  );
  const sectionHowItWorksCardDescription2 = (
    <FormattedMessage id="SectionHowItWorks.cardDescription2" />
  );
  const sectionHowItWorksCardDescription3 = (
    <FormattedMessage id="SectionHowItWorks.cardDescription3" />
  );

  const sectionHowItWorksInfoImageName = (
    <FormattedMessage id="MarketingTeamSolutionPage.infoImageName" />
  );
  const sectionHowItWorksInfoImageText1 = (
    <FormattedMessage id="MarketingTeamSolutionPage.infoImageText1" />
  );
  const sectionHowItWorksInfoImageText2 = (
    <FormattedMessage id="MarketingTeamSolutionPage.infoImageText2" />
  );
  const sectionHowItWorksInfoImageText3 = (
    <FormattedMessage id="MarketingTeamSolutionPage.infoImageText3" />
  );
  const sectionHowItWorksInfoImageText4 = (
    <FormattedMessage id="MarketingTeamSolutionPage.infoImageText4" />
  );

  const howItWorksCardTitle1 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle1" />;
  const howItWorksCardText1 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText1" />;
  const howItWorksCardCheckmarkText1 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText1" />
  );
  const howItWorksCardTitle2 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle2" />;
  const howItWorksCardText2 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText2" />;
  const howItWorksCardCheckmarkText2 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText2" />
  );
  const howItWorksCardTitle3 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardTitle3" />;
  const howItWorksCardText3 = <FormattedMessage id="MarketingSolutionPage.howItWorksCardText3" />;
  const howItWorksCardCheckmarkText3 = (
    <FormattedMessage id="MarketingSolutionPage.howItWorksCardCheckmarkText3" />
  );

  const HowItWorksCard = ({ title, description, checkmarkText }) => {
    return (
      <div className={css.howItWorksCard}>
        <div>
          <div className={css.howItWorksCardTitle}>{title}</div>
          <div className={css.howItWorksCardDescription}>{description}</div>
        </div>
        <div className={css.howItWorksCardCheckmarkContainer}>
          <BsFillCheckCircleFill className={css.howItWorksCardCheckmark} />
          {checkmarkText}
        </div>
      </div>
    );
  };

  const handleShowSpaceHome = () => {
    if (typeof window === 'object') {
      window.Intercom('showSpace', 'home');
    }
  };

  if (currentUserFetchInProgress) {
    return <div></div>;
  }
  if (!currentUserFetchInProgress && currentUser) {
    return <NamedRedirect name="DashboardPage" />;
  }
  if (!currentUserFetchInProgress && !currentUser) {
    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[
          { url: `${config.canonicalRootURL}${facebookImage}`, width: 1200, height: 630 },
        ]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="LandingPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <ul className={css.sections}>
              <li className={css.section}>
                <div className={css.heroContainer}>
                  <SectionLandingPageHero
                    history={history}
                    location={location}
                    heroTitle1={heroTitle1}
                    heroTitle2={heroTitle2}
                    heroSubtitle={heroSubtitle}
                    atLandingPage={true}
                  />
                </div>
              </li>
              <li className={css.section}>
                <SuccessStoryBanner
                  imgSrc={succesStoryBannerImage}
                  textId={'SuccessStoryBanner.systamText'}
                  fromId={'SuccessStoryBanner.systamFrom'}
                  companyId={'SuccessStoryBanner.systamCompany'}
                  pageName={'SuccessStorySystamPage'}
                  buttonTxtId={'SuccessStoryBanner.tulusCTAText'}
                />
              </li>
              <li className={css.section}>
                <SectionPortfolioLogos />
              </li>
              <li className={css.section}>
                <SectionFreedomlyStats />
              </li>
              <li className={css.section}>
                <SectionLocations />
              </li>
              <li className={css.section}>
                <SolutionPageCustomerReviews atLandingPage={true} />
              </li>
              <li className={css.section}>
                <SectionHowItWorks
                  header={sectionHowItWorksHeader}
                  cardTitle1={sectionHowItWorksCardTitle1}
                  cardTitle2={sectionHowItWorksCardTitle2}
                  cardTitle3={sectionHowItWorksCardTitle3}
                  cardDescription1={sectionHowItWorksCardDescription1}
                  cardDescription2={sectionHowItWorksCardDescription2}
                  cardDescription3={sectionHowItWorksCardDescription3}
                  infoImageName={sectionHowItWorksInfoImageName}
                  infoImageText1={sectionHowItWorksInfoImageText1}
                  infoImageText2={sectionHowItWorksInfoImageText2}
                  infoImageText3={sectionHowItWorksInfoImageText3}
                  infoImageText4={sectionHowItWorksInfoImageText4}
                  cta={sectionHowItWorksCTA}
                  atLandingPage={true}
                />
              </li>
              <li className={css.section}>
                <div className={css.sectionHowItWorksContent}>
                  <div className={css.howItWorksTitle}>
                    <FormattedMessage id="StartSellingInfoPage.howItWorksTitle" />
                  </div>
                  <div className={css.howItWorksCardContainer}>
                    <HowItWorksCard
                      title={howItWorksCardTitle1}
                      description={howItWorksCardText1}
                      checkmarkText={howItWorksCardCheckmarkText1}
                    />
                    <HowItWorksCard
                      title={howItWorksCardTitle2}
                      description={howItWorksCardText2}
                      checkmarkText={howItWorksCardCheckmarkText2}
                    />
                    <HowItWorksCard
                      title={howItWorksCardTitle3}
                      description={howItWorksCardText3}
                      checkmarkText={howItWorksCardCheckmarkText3}
                    />
                  </div>
                  <div className={css.howItWorksCTA}>
                    <NamedLink className={css.howItWorksCTAButton} name="SignupPage">
                      <FormattedMessage id="LandingPage.howItWorksCTA" />
                    </NamedLink>
                  </div>
                </div>
              </li>
            </ul>
            <div className={css.contactSection}>
              <div className={css.contactSectionContactCard}>
                <div className={css.contactCard}>
                  <div className={css.contactCardAvatar}>
                    <img src={miikkaImg} alt="Contact" />
                  </div>
                  <div className={css.contactCardName}>Miikka Salminen, CEO</div>
                  <div className={css.contactCardInformation}>miikka@freedomly.io</div>
                  <div className={css.contactCardInformation}>+358 45 268 2218</div>
                  <div>
                    <ExternalLink
                      className={css.contactSectionContactButton}
                      onClick={handleShowSpaceHome}
                    >
                      <FormattedMessage id="MarketingSolutionPage.heroContactButton" />
                    </ExternalLink>
                  </div>
                </div>
              </div>
              <div className={css.contactSectionTextContent}>
                <div className={css.contactSectionTitle}>
                  <FormattedMessage id="MarketingSolutionPage.contactSectionTitle" />
                </div>
                <div className={css.contactSectionContentText}>
                  <FormattedMessage id="MarketingSolutionPage.contactSectionText" />
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  currentUser: propTypes.user,
  currentUserFetchInProgress: bool.isRequired,

  usersAtSectionTalent: arrayOf(propTypes.user),
};

const mapStateToProps = state => {
  const { currentUser, currentUserFetchInProgress } = state.user;
  const { usersAtSectionTalent } = state.LandingPage;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    usersAtSectionTalent,
    currentUserFetchInProgress,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

// LandingPage.loadData = loadData;

export default LandingPage;
