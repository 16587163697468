import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import { injectIntl } from '../../util/reactIntl';
import {
  Form,
  FieldTextInput,
  SecondaryButton,
  Button,
  FieldSelectModern,
  IconClose,
  InlineTextButton,
  FieldCheckbox,
  FieldRadioButton,
  LinkedInAuthButton,
} from '../../components';
import { required } from '../../util/validators';
import { generateMonthsList, generateYearsList } from '../../util/dates';

import css from './SignupProviderForms.css';

const SignupProviderWorkExperienceForm = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const requiredMessage = id => required(props.intl.formatMessage({ id }));

  const validateWorkExperience = (fieldSet = {}, dontAddWorkExperience) => {
    if (dontAddWorkExperience) return undefined; // Skip validation if flag is true

    const error = {};
    const fieldsToValidate = ['customerName', 'task', 'description', 'startMonth', 'startYear'];

    // Validate required fields
    fieldsToValidate.forEach(field => {
      if (!fieldSet[field]) {
        error[field] = requiredMessage(`SignupProviderForms.${field}Required`);
      }
    });

    // Validate end date if the job doesn't continue
    if (!fieldSet.jobContinues) {
      if (!fieldSet.endMonth) {
        error.endMonth = requiredMessage('SignupProviderForms.dateRequired');
      }
      if (!fieldSet.endYear) {
        error.endYear = requiredMessage('SignupProviderForms.dateRequired');
      }
    }

    // Validate formOfEmployment
    if (!fieldSet.formOfEmployment) {
      error.formOfEmployment = requiredMessage('SignupProviderForms.formOfEmploymentRequired');
    }

    // Validate taskType
    if (!fieldSet.taskType) {
      error.taskType = requiredMessage('SignupProviderForms.marketTypeRequired');
    }

    return Object.keys(error).length > 0 ? error : undefined;
  };

  const isAddButtonDisabled = values => {
    if (values.dontAddWorkExperience) return false;

    return values.workExperience?.some(item => !!validateWorkExperience(item, false));
  };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      validate={values => {
        const errors = {};

        // Ensure workExperience is always an array
        if (Array.isArray(values.workExperience)) {
          errors.workExperience = values.workExperience.map(item =>
            validateWorkExperience(item, values.dontAddWorkExperience)
          );
        } else {
          errors.workExperience = [];
        }

        return errors;
      }}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          onPrev,
          form,
          intl,
          form: {
            mutators: { push },
          },
          invalid,
          values,
          isModal,
          initialValues,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const skipStep = values.dontAddWorkExperience;

        const workExperienceList = values.workExperience || [];
        const selectedWorkExperience = workExperienceList[selectedIndex] || {};

        const submitDisabled =
          (invalid && !skipStep) || (skipStep && workExperienceList.length > 0);

        const hideEndDate = selectedWorkExperience.jobContinues;
        //  const hideEndDate = index => values.workExperience[index].jobContinues;

        const changed =
          JSON.stringify(initialValues.workExperience) !== JSON.stringify(values.workExperience);
        const isEmptyList = workExperienceList.length === 0;
        const allowEmptySubmit = isEmptyList && isModal;
        const modalSubmitDisabled = !allowEmptySubmit && (invalid || !changed);

        const heading = intl.formatMessage({ id: 'SignupProviderForms.workExperienceHeading' });

        const nextButtonText = intl.formatMessage({ id: 'SignupProviderForms.nextButton' });
        const previousButtonText = intl.formatMessage({ id: 'SignupProviderForms.previousButton' });
        const addButtonText = intl.formatMessage({ id: 'SignupProviderForms.addButton' });
        const okButtonText = intl.formatMessage({ id: 'SignupProviderForms.okButton' });

        const customerNameLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNameLabel',
        });
        const customerNamePlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceCustomerNamePlaceholder',
        });

        const taskLabel = intl.formatMessage({ id: 'SignupProviderForms.workExperienceTaskLabel' });
        const taskPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceTaskPlaceholder',
        });

        const startLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceStartLabel',
        });
        const endLabel = intl.formatMessage({ id: 'SignupProviderForms.workExperienceEndLabel' });

        const monthPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.monthPlaceholder',
        });
        const yearPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.yearPlaceholder',
        });

        const jobContinuesLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceContinuesLabel',
        });

        const wasEmploymentLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceWasEmploymentLabel',
        });

        const wasFreelancerLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceWasFreelancerLabel',
        });

        const formOfEmploymentRequired = intl.formatMessage({
          id: 'SignupProviderForms.formOfEmploymentRequired',
        });

        const marketTypeB2BLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceMarketTypeB2BLabel',
        });

        const marketTypeB2CLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceMarketTypeB2CLabel',
        });

        const marketTypeNoTypeLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceMarketTypeNoTypeLabel',
        });

        const marketTypeRequired = intl.formatMessage({
          id: 'SignupProviderForms.marketTypeRequired',
        });

        const descriptionLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionLabel',
        });
        const descriptionPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDescriptionPlaceholder',
        });

        const dontAddWorkExperienceLabel = intl.formatMessage({
          id: 'SignupProviderForms.workExperienceDontAddLabel',
        });

        const workExperiencePoint1 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint1',
        });

        const workExperiencePoint2 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint2',
        });

        const workExperiencePoint3 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint3',
        });

        const workExperiencePoint4 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint4',
        });

        const workExperiencePoint5 = intl.formatMessage({
          id: 'SignupProviderForms.workExperiencePoint5',
        });

        const handleAddWorkExperience = () => {
          push('workExperience', {});
          setSelectedIndex(workExperienceList.length);
        };

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeadingNoMargin}>
                {heading}
                {isModal ? (
                  <div className={css.submitContainer}>
                    <Button
                      rootClassName={css.nextButton}
                      type="Submit"
                      disabled={modalSubmitDisabled}
                    >
                      {okButtonText}
                    </Button>
                  </div>
                ) : null}
              </h2>
              <ul className={css.pointerList}>
                <li>{workExperiencePoint1}</li>
                <li>{workExperiencePoint2}</li>
                <li>{workExperiencePoint3}</li>
                <li>{workExperiencePoint4}</li>
                <li>{workExperiencePoint5}</li>
              </ul>
              {isModal && (
                <LinkedInAuthButton
                  redirectUri={'profile-settings'}
                  modal={'workExperience'}
                  isModal={isModal}
                />
              )}
              <div className={css.twoColumnLayout}>
                {/* <p className={css.description}>{description}</p> */}
                <div className={css.leftColumn}>
                  <ul className={css.workExperienceList}>
                    {workExperienceList.length > 0 &&
                      workExperienceList.map((experience, index) => {
                        const isValid = !validateWorkExperience(
                          experience,
                          values.dontAddWorkExperience
                        );

                        return (
                          <div key={index} className={css.workExperienceItemContainer}>
                            <li
                              key={index}
                              className={classNames(css.workExperienceItem, {
                                [css.selected]: index === selectedIndex,
                                [css.invalid]: !isValid, // Add `invalid` class if not valid
                              })}
                              onClick={() => setSelectedIndex(index)}
                            >
                              <div>{experience?.customerName || `${customerNameLabel}`}</div>
                              <div>{experience?.task || `${taskLabel}`}</div>
                            </li>
                            <span
                              className={css.deleteIconContainer}
                              onClick={() => {
                                form.mutators.remove('workExperience', index);
                                setSelectedIndex(index - 1);
                              }}
                            >
                              <IconClose rootClassName={css.deleteIcon} />
                            </span>
                          </div>
                        );
                      })}
                  </ul>

                  <div className={css.column}>
                    <InlineTextButton
                      type="button"
                      className={
                        isAddButtonDisabled(values) ? css.addToArrayDisabled : css.addToArray
                      }
                      onClick={() => handleAddWorkExperience()}
                      disabled={isAddButtonDisabled(values)}
                    >
                      {addButtonText}
                    </InlineTextButton>
                    {!isModal && (
                      <FieldCheckbox
                        id="dontAddWorkExperience"
                        name="dontAddWorkExperience"
                        label={dontAddWorkExperienceLabel}
                        defaultValue={false}
                      />
                    )}
                  </div>
                </div>
                {workExperienceList.length > 0 && (
                  <div className={css.rightColumn}>
                    <FieldTextInput
                      id={`workExperience.${selectedIndex}.customerName`}
                      name={`workExperience.${selectedIndex}.customerName`}
                      type="text"
                      label={customerNameLabel}
                      placeholder={customerNamePlaceholder}
                      disabled={values.dontAddWorkExperience}
                    />
                    <FieldTextInput
                      id={`workExperience.${selectedIndex}.task`}
                      name={`workExperience.${selectedIndex}.task`}
                      type="text"
                      label={taskLabel}
                      placeholder={taskPlaceholder}
                      disabled={values.dontAddWorkExperience}
                    />
                    <div className={css.workingMethodsContainer}>
                      <label className={css.label}>
                        {intl.formatMessage({
                          id: 'SignupProviderForms.workExperienceFormOfEmploymentLabel',
                        })}
                      </label>
                      <div className={css.workingMethods}>
                        <FieldRadioButton
                          id={`workExperience.${selectedIndex}.formOfEmploymentOption1`}
                          name={`workExperience.${selectedIndex}.formOfEmployment`}
                          label={wasEmploymentLabel}
                          value="employee"
                          checkboxIcon
                          validate={required(formOfEmploymentRequired)}
                          disabled={values.dontAddWorkExperience}
                        />
                        <FieldRadioButton
                          id={`workExperience.${selectedIndex}.formOfEmploymentOption2`}
                          name={`workExperience.${selectedIndex}.formOfEmployment`}
                          label={wasFreelancerLabel}
                          value="freelancer"
                          checkboxIcon
                          validate={required(formOfEmploymentRequired)}
                          disabled={values.dontAddWorkExperience}
                        />
                      </div>
                    </div>

                    <div className={css.workingMethodsContainer}>
                      <label className={css.label}>
                        {intl.formatMessage({
                          id: 'SignupProviderForms.workExperienceMarketTypeLabel',
                        })}
                      </label>
                      <div className={css.workingMethods}>
                        <FieldCheckbox
                          id={`workExperience.${selectedIndex}.marketTypeOption1`}
                          name={`workExperience.${selectedIndex}.taskType`}
                          label={marketTypeB2BLabel}
                          value="B2B"
                          checkboxIcon
                          validate={required(marketTypeRequired)}
                          disabled={values.dontAddWorkExperience}
                        />
                        <FieldCheckbox
                          id={`workExperience.${selectedIndex}.marketTypeOption2`}
                          name={`workExperience.${selectedIndex}.taskType`}
                          label={marketTypeB2CLabel}
                          value="B2C"
                          checkboxIcon
                          validate={required(marketTypeRequired)}
                          disabled={values.dontAddWorkExperience}
                        />
                        <FieldCheckbox
                          id={`workExperience.${selectedIndex}.marketTypeOption3`}
                          name={`workExperience.${selectedIndex}.taskType`}
                          label={marketTypeNoTypeLabel}
                          value="noType"
                          checkboxIcon
                          validate={required(marketTypeRequired)}
                          disabled={values.dontAddWorkExperience}
                        />
                      </div>
                    </div>
                    <div className={css.datesContainer}>
                      <FieldSelectModern
                        id={`workExperience.${selectedIndex}.startMonth`}
                        name={`workExperience.${selectedIndex}.startMonth`}
                        options={generateMonthsList()}
                        placeholder={monthPlaceholder}
                        label={startLabel}
                        disabled={values.dontAddWorkExperience}
                      />
                      <FieldSelectModern
                        id={`workExperience.${selectedIndex}.startYear`}
                        name={`workExperience.${selectedIndex}.startYear`}
                        options={generateYearsList(1900)}
                        placeholder={yearPlaceholder}
                        label={startLabel}
                        disabled={values.dontAddWorkExperience}
                      />
                      {!hideEndDate && (
                        <>
                          <FieldSelectModern
                            id={`workExperience.${selectedIndex}.endMonth`}
                            name={`workExperience.${selectedIndex}.endMonth`}
                            options={generateMonthsList()}
                            placeholder={monthPlaceholder}
                            label={endLabel}
                            disabled={values.dontAddWorkExperience}
                          />
                          <FieldSelectModern
                            id={`workExperience.${selectedIndex}.endYear`}
                            name={`workExperience.${selectedIndex}.endYear`}
                            options={generateYearsList(1900)}
                            placeholder={yearPlaceholder}
                            label={endLabel}
                            disabled={values.dontAddWorkExperience}
                          />
                        </>
                      )}
                    </div>
                    <FieldCheckbox
                      id={`workExperience.${selectedIndex}.jobContinues`}
                      name={`workExperience.${selectedIndex}.jobContinues`}
                      label={jobContinuesLabel}
                      disabled={values.dontAddWorkExperience}
                    />
                    <FieldTextInput
                      id={`workExperience.${selectedIndex}.description`}
                      name={`workExperience.${selectedIndex}.description`}
                      type="textarea"
                      label={descriptionLabel}
                      placeholder={descriptionPlaceholder}
                      disabled={values.dontAddWorkExperience}
                    />
                  </div>
                )}
              </div>
              <div className={css.submitContainer}>
                {!isModal && (
                  <SecondaryButton rootClassName={css.backButton} onClick={onPrev}>
                    {previousButtonText}
                  </SecondaryButton>
                )}
                {!isModal ? (
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {nextButtonText}
                  </Button>
                ) : null}
              </div>
            </Form>
          </div>
        );
      }}
    />
  );
};

SignupProviderWorkExperienceForm.defaultProps = {
  isModal: false,
};

export default injectIntl(SignupProviderWorkExperienceForm);
