import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { NamedLink, SectionBestTalent, SectionCustomerLogos } from '..';
import css from './SectionLandingPageHero.css';

const SectionLandingPageHero = withRouter(props => {
  const {
    rootClassName,
    className,
    heroTitle1,
    heroTitle2,
    heroSubtitle,
    atLandingPage,
    noButton,
    atProLandingPage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroTopBlock}>
          <div className={css.heroCaption}>{heroTitle1}</div>
          <h1 className={atLandingPage ? css.heroMainTitle : css.otherMainTitle}>{heroTitle2}</h1>

          <h2 className={atProLandingPage ? css.proLandingPageSubTitle : css.heroSubTitle}>
            {heroSubtitle}
          </h2>

          {!noButton ? (
            <div className={css.heroButtonContainer}>
              <NamedLink className={css.heroButton} name="SignupPage">
                <FormattedMessage id="SectionHero.heroButton" />
              </NamedLink>
            </div>
          ) : null}
        </div>
        <SectionCustomerLogos
          toShow={[
            'peikko',
            'olefit',
            'eerikkila',
            'corego',
            'mobal',
            'strongest',
            'systam',
            'zervant',
          ]}
        />
        <SectionBestTalent />
      </div>
    </div>
  );
});

SectionLandingPageHero.defaultProps = { rootClassName: null, className: null };

SectionLandingPageHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLandingPageHero;
