import React from 'react';

import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import { Form, FieldTextEditor, Button, FieldTextInput } from '../../components';
import { injectIntl } from '../../util/reactIntl';
import { required, textEditorValidation, composeValidators } from '../../util/validators';

import css from './SignupProviderForms.css';

const ProfileSettingsBioWhoAmIForm = props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          form,
          intl,
          invalid,
          isValidProvider,
          pristine,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);

        const submitDisabled = invalid || pristine;

        const heading = intl.formatMessage({ id: 'SignupProviderForms.bioHeading' });

        const okButtonText = intl.formatMessage({
          id: 'SignupProviderForms.okButton',
        });

        const bioLabel = intl.formatMessage({ id: 'SignupProviderForms.bioBioLabel' });
        const bioPlaceholder = intl.formatMessage({ id: 'SignupProviderForms.bioBioPlaceholder' });
        const whoAmILabel = intl.formatMessage({ id: 'SignupProviderForms.whoAmILabel' });
        const whoAmIPlaceholder = intl.formatMessage({
          id: 'SignupProviderForms.whoAmIPlaceholder',
        });

        const bioPoint1 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint1' });
        const bioPoint2 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint2' });
        const bioPoint3 = intl.formatMessage({ id: 'SignupProviderForms.bioPoint3' });

        const whoAmIPoint1 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint1' });
        const whoAmIPoint2 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint2' });
        const whoAmIPoint3 = intl.formatMessage({ id: 'SignupProviderForms.whoAmIPoint3' });

        const bioRequired = intl.formatMessage({ id: 'SignupProviderForms.bioRequired' });
        const whoAmIRequired = intl.formatMessage({ id: 'SignupProviderForms.whoAmIRequired' });
        const externalLinksMessage = intl.formatMessage({
          id: 'RichTextEditor.externalLinksError',
        });

        return (
          <div className={classes}>
            <Form className={css.form} onSubmit={values => handleSubmit(values, form)}>
              <h2 className={css.mainHeading}>
                {heading}
                <div className={css.submitContainer}>
                  <Button rootClassName={css.nextButton} type="Submit" disabled={submitDisabled}>
                    {okButtonText}
                  </Button>
                </div>
              </h2>
              {isValidProvider ? (
                <div className={css.whoAmI}>
                  <FieldTextInput
                    type="text"
                    id="whoAmI"
                    name="whoAmI"
                    label={whoAmILabel}
                    maxLength="50"
                    placeholder={whoAmIPlaceholder}
                    validate={required(whoAmIRequired)}
                  />
                  <ul className={css.pointerList}>
                    <li>{whoAmIPoint1}</li>
                    <li>{whoAmIPoint2}</li>
                    <li>{whoAmIPoint3}</li>
                  </ul>
                </div>
              ) : null}

              <FieldTextEditor
                id="bio"
                name="bio"
                quillClass={css.quillClass}
                label={bioLabel}
                placeholder={bioPlaceholder}
                validate={composeValidators(
                  textEditorValidation(externalLinksMessage),
                  required(bioRequired)
                )}
              />
              {isValidProvider ? (
                <ul className={css.pointerList}>
                  <li>{bioPoint1}</li>
                  <li>{bioPoint2}</li>
                  <li>{bioPoint3}</li>
                </ul>
              ) : null}
            </Form>
          </div>
        );
      }}
    />
  );
};

export default injectIntl(ProfileSettingsBioWhoAmIForm);
