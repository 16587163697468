import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  AttachmentPage,
  AuthenticationPage,
  CheckoutPage,
  ContactDetailsPage,
  EditListingPage,
  EditProjectListingPage,
  EmailVerificationPage,
  InboxPage,
  LandingPage,
  ListingPage,
  ManageListingsPage,
  ManageProjectListingsPage,
  NotFoundPage,
  PasswordChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  StripePayoutPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  ProfilePage,
  LeaveOffPlatformTestimonialPage,
  ProfileSettingsPage,
  ProjectListingPage,
  ProjectSearchPage,
  SearchPage,
  StyleguidePage,
  StartBuyingInfoPage,
  StartSellingInfoPage,
  SubscriptionPage,
  ProjectProposalPage,
  TermsOfServicePage,
  TransactionPage,
  DashboardPage,
  ApplicationInboxPage,
  ApplicationPage,
  ProLandingPage,
  ParentCategoryPage,
  ChildCategoryPage,
  CategoriesPage,
  SignupProviderPage,
  SignupPage,
  FavoritesPage,
  SuccessStoriesPage,
  SuccessStoryZervantPage,
  SuccessStoryTulusPage,
  SuccessStoryPiirlaPage,
  SuccessStoryCoregoPage,
  SuccessStorySystamPage,
  SuccessStoryEerikkilaPage,
  SuccessStoryFundisPage,
  SuccessStoryPeikkoPage,
  ProjectCategoryLandingPage,
  SignUpProjectPage,
  AboutUsPage,
  DigitalAgencySolutionPage,
  SmallBusinessSolutionPage,
  MarketingTeamSolutionPage,
  DashboardProPage,
  DashboardMarketplacePage,
  FreelancerFinlandPage,
  StaticFreelancerPage,
  ReviewsPage,
  TaxInformationPage,
  StartupSolutionPage,
  InHouseSolutionPage,
  OrganizationSolutionPage,
  CommunicationSolutionPage,
  MarketingSolutionPage,
  CreativeContentSolutionPage,
} from './containers';

// import { contentCreatingPageContent, articlesAndBlogContent } from './categoryPageContents';
import { filters } from './marketplace-custom-config.js';
import { pageContents } from './categoryPageContents';
import { projectPageContents } from './projectCategoryLandingPageContents';
import { staticFreelancerPageContents } from './staticFreelancerPageContents';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'TaxInformationPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'SubscriptionPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

const createRoutes = (filters, pageContents) => {
  const pathConfig = filters.find(x => x.id === 'category').config.options;
  let arr = [];

  const switchBetweenParentOrChildComponent = (parent, pageContents, option, props) => {
    if (parent) {
      return (
        <ParentCategoryPage
          {...props}
          content={pageContents.find(y => y.id === option.key)}
          routeName={option.name}
        />
      );
    }
    return (
      <ChildCategoryPage
        {...props}
        content={
          pageContents.flatMap(obj =>
            obj.childCategories.filter(category => {
              return category.id === option.key;
            })
          )[0]
        }
        routeName={option.name}
      />
    );
  };

  pathConfig.forEach(option =>
    option.path !== undefined
      ? arr.push({
          path: option.path,
          name: option.name,
          component: props =>
            switchBetweenParentOrChildComponent(option.parent === '', pageContents, option, props),
          loadData:
            option.parent === ''
              ? (params, search) =>
                  ParentCategoryPage.loadData({ params, search, category: option.key })
              : (params, search) =>
                  ChildCategoryPage.loadData({ params, search, category: option.key }),
        })
      : null
  );

  return arr;
};

const createProjectRoutes = pageContents => {
  let arr = [];

  pageContents.forEach(option =>
    arr.push({
      path: `/${option.path}`,
      name: option.name,
      component: props => <ProjectCategoryLandingPage {...props} content={option} />,
    })
  );

  return arr;
};

const createStaticFreelancerRoutes = pageContents => {
  let arr = [];

  pageContents.forEach(option =>
    arr.push({
      path: `/freelancer${option.path}`,
      name: option.name,
      component: props => <StaticFreelancerPage {...props} content={option} />,
    })
  );

  return arr;
};

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = currentUser => {
  return [
    ...createRoutes(filters, pageContents),
    ...createProjectRoutes(projectPageContents),
    ...createStaticFreelancerRoutes(staticFreelancerPageContents),
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
      // loadData: LandingPage.loadData,
    },
    // URL not in use anymore. Kept here for SEO
    {
      path: '/home',
      name: 'HomePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/application',
      name: 'ApplicationBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/application/order/:id',
      name: 'ApplicationOrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ApplicationPage {...props} transactionRole="customer" />,
      loadData: params => ApplicationPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: ApplicationPage.setInitialValues,
    },
    {
      path: '/application/sale/:id',
      name: 'ApplicationSalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ApplicationPage {...props} transactionRole="provider" />,
      loadData: params => ApplicationPage.loadData({ ...params, transactionRole: 'provider' }),
      setInitialValues: ApplicationPage.setInitialValues,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      loadData: DashboardPage.loadData,

      component: props => <DashboardPage {...props} />,
    },
    {
      path: '/kategoriat',
      name: 'CategoriesPage',
      component: props => <CategoriesPage {...props} />,
    },
    {
      path: '/ps',
      name: 'ProjectSearchPage',
      component: props => <Redirect to="/dashboard" />,
    },
    {
      path: '/avoimet-toimeksiannot',
      name: 'ProjectSearchPage',
      component: props => <ProjectSearchPage {...props} />,
      loadData: ProjectSearchPage.loadData,
    },
    {
      path: '/psc/:category',
      name: 'ProjectSearchCategoryPage',
      component: props => <ProjectSearchPage {...props} />,
      loadData: ProjectSearchPage.loadData,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/sc/:category',
      name: 'SearchCategoryPage',
      component: props => <SearchPage {...props} />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/filters',
      name: 'SearchFiltersPage',
      component: props => <SearchPage {...props} tab="filters" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/listings',
      name: 'SearchListingsPage',
      component: props => <SearchPage {...props} tab="listings" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/s/map',
      name: 'SearchMapPage',
      component: props => <SearchPage {...props} tab="map" />,
      loadData: SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/pl/:slug/:id',
      name: 'ProjectListingPage',
      component: props => <ProjectListingPage {...props} />,
      loadData: ProjectListingPage.loadData,
    },
    {
      path: '/pl/:slug/:id/proposal',
      name: 'ProjectProposalPage',
      auth: true,
      component: props => <ProjectProposalPage {...props} />,
      loadData: ProjectProposalPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/pl/:slug/:id/:variant',
      name: 'ProjectListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProjectListingPage {...props} />,
      loadData: ProjectListingPage.loadData,
    },
    {
      path: '/tyoilmoitus',
      name: 'SignUpProjectPage',
      component: props => <SignUpProjectPage {...props} />,
    },
    {
      path: '/työilmoitus',
      name: 'SignUpProjectPageOld',
      component: props => <NamedRedirect name="SignUpProjectPage" />,
    },
    {
      path: '/verkkosivujen-tekijä',
      name: 'WebDeveloperPageProjectOld',
      component: props => <Redirect to="/verkkosivujen-tekija" />,
    },
    {
      path: '/verkkokauppa-tekijä',
      name: 'EcommmerceDeveloperPageProjectOld',
      component: props => <Redirect to="/verkkokaupan-tekija" />,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    {
      path: '/pl/new',
      name: 'NewProjectListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditProjectListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'description' }}
        />
      ),
    },
    {
      path: '/pl/:slug/:id/:type/:tab',
      name: 'EditProjectListingPage',
      auth: true,
      component: props => <EditProjectListingPage {...props} />,
      loadData: EditProjectListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/pl/:id',
      name: 'ProjectListingPageCanonical',
      component: props => <ProjectListingPage {...props} />,
      loadData: ProjectListingPage.loadData,
    },
    {
      path: '/a/:id/:name',
      name: 'AttachmentPage',
      component: props => <AttachmentPage {...props} />,
      loadData: AttachmentPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/leave-testimonial/:userId/:workExperienceId',
      name: 'LeaveOffPlatformTestimonialPage',
      component: props => <LeaveOffPlatformTestimonialPage {...props} />,
      loadData: LeaveOffPlatformTestimonialPage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: props => <SignupPage {...props} />,
      // component: props => <AuthenticationPage {...props} tab="signup" isSignupProvider={false} />,
    },
    {
      path: '/signup/provider',
      name: 'SignupProviderPage',
      component: props => <SignupProviderPage {...props} />,
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <RedirectToLandingPage {...props} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/applications',
      name: 'ApplicationInboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ApplicationInboxPage {...props} />,
      loadData: ApplicationInboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      loadData: params => TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      loadData: ManageListingsPage.loadData,
    },
    {
      path: '/projects',
      name: 'ManageProjectListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageProjectListingsPage {...props} />,
      loadData: ManageProjectListingsPage.loadData,
    },
    {
      path: '/start_buying',
      name: 'StartBuyingInfoPage',
      component: props => <StartBuyingInfoPage {...props} />,
    },
    {
      path: '/start_selling',
      name: 'StartSellingInfoPage',
      component: props => <StartSellingInfoPage {...props} />,
    },
    {
      path: '/freedomly-pro',
      name: 'ProLandingPage',
      component: props => <ProLandingPage {...props} />,
    },
    {
      path: '/asiakastarinat',
      name: 'SuccessStoriesPage',
      component: props => <SuccessStoriesPage {...props} />,
    },
    {
      path: '/asiakastarinat/zervant',
      name: 'SuccessStoryZervantPage',
      component: props => <SuccessStoryZervantPage {...props} />,
    },
    {
      path: '/asiakastarinat/tulus',
      name: 'SuccessStoryTulusPage',
      component: props => <SuccessStoryTulusPage {...props} />,
    },
    {
      path: '/asiakastarinat/piirla',
      name: 'SuccessStoryPiirlaPage',
      component: props => <SuccessStoryPiirlaPage {...props} />,
    },
    {
      path: '/asiakastarinat/corego',
      name: 'SuccessStoryCoregoPage',
      component: props => <SuccessStoryCoregoPage {...props} />,
    },
    {
      path: '/asiakastarinat/systam',
      name: 'SuccessStorySystamPage',
      component: props => <SuccessStorySystamPage {...props} />,
    },
    {
      path: '/asiakastarinat/eerikkila',
      name: 'SuccessStoryEerikkilaPage',
      component: props => <SuccessStoryEerikkilaPage {...props} />,
    },
    {
      path: '/asiakastarinat/fundis',
      name: 'SuccessStoryFundisPage',
      component: props => <SuccessStoryFundisPage {...props} />,
    },
    {
      path: '/asiakastarinat/peikko',
      name: 'SuccessStoryPeikkoPage',
      component: props => <SuccessStoryPeikkoPage {...props} />,
    },
    {
      path: '/tietoa-meista',
      name: 'AboutUsPage',
      component: props => <AboutUsPage {...props} />,
    },
    {
      path: '/ratkaisut/digitoimisto',
      name: 'DigitalAgencySolutionPage',
      component: props => <DigitalAgencySolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/markkinointitiimit',
      name: 'MarketingTeamSolutionPage',
      component: props => <MarketingSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/pienyrityksille',
      name: 'SmallBusinessSolutionPage',
      component: props => <SmallBusinessSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/startup',
      name: 'StartupSolutionPage',
      component: props => <StartupSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/in-house',
      name: 'InHouseSolutionPage',
      component: props => <InHouseSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/suuret-yritykset',
      name: 'OrganizationSolutionPage',
      component: props => <OrganizationSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/markkinointi',
      name: 'MarketingSolutionPage',
      component: props => <MarketingSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/viestinta',
      name: 'CommunicationSolutionPage',
      component: props => <CommunicationSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/viestintä',
      name: 'CommunicationSolutionPageOld',
      component: () => <NamedRedirect name="CommunicationSolutionPage" />,
    },
    {
      path: '/ratkaisut/luovat-sisallot',
      name: 'CreativeContentSolutionPage',
      component: props => <CreativeContentSolutionPage {...props} />,
    },
    {
      path: '/ratkaisut/luovat-sisällöt',
      name: 'CreativeContentSolutionPageOld',
      component: () => <NamedRedirect name="CreativeContentSolutionPage" />,
    },
    {
      path: '/freelancer/ulkoistettu-markkinointipäällikkö',
      name: 'FreelancerOutsourcedMarketingManagerPageOld',
      component: () => <NamedRedirect name="FreelancerOutsourcedMarketingManagerPage" />,
    },
    {
      path: '/freelancer/ulkoistettu-viestintäpäällikkö',
      name: 'FreelancerOutsourcedCommunicationManagerPageOld',
      component: () => <NamedRedirect name="FreelancerOutsourcedCommunicationManagerPage" />,
    },
    {
      path: '/dashboard-pro',
      name: 'DashboardProPage',
      auth: true,
      component: props => <DashboardProPage {...props} />,
    },
    {
      path: '/dashboard-marketplace',
      name: 'DashboardMarketplacePage',
      auth: true,
      component: props => <DashboardMarketplacePage {...props} />,
    },
    {
      path: '/freelancer-suomi',
      name: 'FreelancerFinlandPage',
      component: props => <FreelancerFinlandPage {...props} />,
    },
    {
      path: '/arvostelut',
      name: 'ReviewsPage',
      component: props => <ReviewsPage {...props} />,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    {
      path: '/account/tax-information',
      name: 'TaxInformationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TaxInformationPage {...props} />,
      loadData: TaxInformationPage.loadData,
    },
    {
      path: '/account/subscription',
      name: 'SubscriptionPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <SubscriptionPage {...props} />,
      loadData: SubscriptionPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PasswordChangePage {...props} />,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/suosikit',
      name: 'FavoritesPage',
      auth: true,
      component: props => <FavoritesPage {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
