import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { injectIntl, FormattedMessage } from '../../util/reactIntl';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SignupProviderInfoStep,
  SignupProviderBioForm,
  SignupProviderEducationForm,
  SignupProviderHourlyFeeForm,
  SignupProviderLocationNLanguageForm,
  SignupProviderSkillsForm,
  SignupProviderWhoAmIFrom,
  SignupProviderLinkedInForm,
  SignupProviderWorkExperienceForm,
  SignupProviderLinksForm,
  SignupProviderCOC,
  ProfileSettingsHobbiesForm,
  SignupProviderIndexForm,
} from '../../forms';

import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  ProgressBar,
  Modal,
  TopbarBlank,
} from '../../components';
import { AuthenticationPage } from '../../containers';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';

import css from './SignupProviderPage.css';

const renderSignUpStep = (step, next, prev, formData, nextFromInfo) => {
  switch (step) {
    case 0:
      return <SignupProviderInfoStep onSubmit={nextFromInfo} initialValues={formData} />;
    case 1:
      return <SignupProviderWhoAmIFrom onSubmit={next} initialValues={formData} />;
    case 2:
      return (
        <SignupProviderSkillsForm
          onSubmit={values => {
            const { experience } = values;
            const newExperience = experience.map(item =>
              item.isMainSkill ? item : { ...item, isMainSkill: false }
            );
            next({ experience: newExperience });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 3:
      return <SignupProviderHourlyFeeForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 4:
      return (
        <SignupProviderLocationNLanguageForm
          onSubmit={next}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 5:
      return <SignupProviderLinksForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 6:
      return <SignupProviderLinkedInForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 7:
      return <SignupProviderEducationForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 8:
      return (
        <SignupProviderWorkExperienceForm
          onSubmit={values => {
            const { workExperience, dontAddWorkExperience } = values;
            const newWorkExperience = workExperience
              ? workExperience.map(item =>
                  item.jobContinues ? { ...item, endMonth: undefined, endYear: undefined } : item
                )
              : [];

            next({ workExperience: newWorkExperience, dontAddWorkExperience });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    case 9:
      return <ProfileSettingsHobbiesForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 10:
      return <SignupProviderBioForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 11:
      return <SignupProviderIndexForm onSubmit={next} onPrev={prev} initialValues={formData} />;
    case 12:
      return (
        <SignupProviderCOC
          onSubmit={values => {
            const { cocAccepted, fnameProvider, lnameProvider } = values;
            next({ cocAccepted, fnameProvider, lnameProvider });
          }}
          onPrev={prev}
          initialValues={formData}
        />
      );
    default:
      return null;
  }
};

const initialFormData = {
  // Array with one empty object for FieldArrays initially to have one set of fields visible on first render.
  experience: [{}],
  languages: [{}],
  education: [{}],
  workExperience: [{}],
  workedWith: [{}],
  hobbies: [{}],
};

const getCurrentStep = () => {
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    return parseInt(sessionStorage.getItem('providerSignupStep'), 10) || 0;
  }
  return 0;
};

const extractMonthAndYear = input => {
  // Define a regular expression to match the month and year
  const regex = /(\w+) (\d{4})/;
  const match = input.match(regex);

  if (!match) {
    return { month: null, year: null };
  }

  // Extract the month name and year from the match
  const monthName = match[1];
  const year = parseInt(match[2], 10);

  // Map month names to month numbers
  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  // Get the month number
  const month = monthMap[monthName];

  if (!month) {
    return { month: null, year: null };
  }

  // Return the month and year as numbers
  return { month, year };
};

const SignupProviderPageComponent = props => {
  const [linkedInDataFetched, setLinkedInDataFetched] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [step, setStep] = useState(getCurrentStep());
  const { onManageDisableScrolling, scrollingDisabled, intl } = props;
  const [isAuthOpen, setIsAuthOpen] = useState(false);

  const getLinkedInData = () => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      const linkedInData = JSON.parse(sessionStorage.getItem('linkedin_data'));
      if (linkedInData) {
        const linkedInEducation = linkedInData
          .filter(item => item.snapshotDomain === 'EDUCATION')
          .map(item => item.snapshotData);

        const education =
          linkedInEducation.length > 0
            ? linkedInEducation[0].map(item => ({
                where: item['School Name'],
                degree: item['Degree Name'],
                when: {
                  key: parseInt(item['End Date'], 10),
                  value: parseInt(item['End Date'], 10),
                  label: parseInt(item['End Date'], 10),
                },
                industry: '',
              }))
            : [];

        const linkedInExperience = linkedInData
          .filter(item => item.snapshotDomain === 'POSITIONS')
          .map(item => item.snapshotData);

        const workExperience =
          linkedInExperience.length > 0
            ? linkedInExperience[0].map(item => ({
                task: item['Title'],
                customerName: item['Company Name'],
                startMonth: extractMonthAndYear(item['Started On']).month
                  ? {
                      key: extractMonthAndYear(item['Started On']).month,
                      value: extractMonthAndYear(item['Started On']).month,
                      label: extractMonthAndYear(item['Started On']).month,
                    }
                  : null,
                startYear: extractMonthAndYear(item['Started On']).year
                  ? {
                      key: extractMonthAndYear(item['Started On']).year,
                      value: extractMonthAndYear(item['Started On']).year,
                      label: extractMonthAndYear(item['Started On']).year,
                    }
                  : null,
                endMonth: item['Finished On']
                  ? {
                      key: extractMonthAndYear(item['Finished On']).month,
                      value: extractMonthAndYear(item['Finished On']).month,
                      label: extractMonthAndYear(item['Finished On']).month,
                    }
                  : null,
                endYear: item['Finished On']
                  ? {
                      key: extractMonthAndYear(item['Finished On']).year,
                      value: extractMonthAndYear(item['Finished On']).year,
                      label: extractMonthAndYear(item['Finished On']).year,
                    }
                  : null,
                jobContinues: !item['Finished On'],
                description: item['Description'],
              }))
            : [];

        // Update formData with LinkedIn data and set state
        const newFormData = { ...formData, education, workExperience };
        setFormData(newFormData);
        sessionStorage.setItem('providerFormData', JSON.stringify(newFormData));
        setLinkedInDataFetched(true); // Mark LinkedIn data as fetched
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      setFormData(getInitialFormData());
    }
  }, [step]);

  const getInitialFormData = () => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      if (!linkedInDataFetched) {
        getLinkedInData();
      }
      return JSON.parse(sessionStorage.getItem('providerFormData')) || initialFormData;
    }
    return initialFormData;
  };

  const nextFromInfo = () => {
    setStep(prevStep => prevStep + 1);
  };

  const setNextStepToStorage = prevStep => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.setItem('providerSignupStep', prevStep + 1);
      setStep(prevStep + 1);
    }
  };

  const setPreviousStepToStorage = prevStep => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.setItem('providerSignupStep', prevStep - 1);
      setStep(prevStep - 1);
    }
  };

  const next = data => {
    const newFormData = {
      ...formData,
      ...data,
    };
    sessionStorage.setItem('providerFormData', JSON.stringify(newFormData));
    setFormData(newFormData);
    return step === 12 ? setUpLastStep(newFormData) : setNextStepToStorage(step);
  };

  const prev = e => {
    e.preventDefault();
    setStep(prevStep => prevStep - 1);
    setPreviousStepToStorage(step);
  };

  const setUpLastStep = data => {
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      sessionStorage.setItem('providerFormData', JSON.stringify(data));
    }
    setIsAuthOpen(true);
  };

  const completedValue = ((step / 12) * 100).toFixed(2);

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'AuthenticationPage.schemaTitleSignup' },
    { siteTitle }
  );

  const nextUpTranslations = [
    { id: 0, translation: 'SignupProviderForms.nextUpStart' },
    { id: 1, translation: 'SignupProviderForms.nextUpSkills' },
    { id: 2, translation: 'SignupProviderForms.nextUpHourlyFee' },
    { id: 3, translation: 'SignupProviderForms.nextUpLocationNLanguage' },
    { id: 4, translation: 'SignupProviderForms.nextUpLinks' },
    { id: 5, translation: 'SignupProviderForms.nextUpEducationAndExperience' },
    { id: 6, translation: 'SignupProviderForms.nextUpEducation' },
    { id: 7, translation: 'SignupProviderForms.nextUpExperience' },
    { id: 8, translation: 'SignupProviderForms.nextUpHobbies' },
    { id: 9, translation: 'SignupProviderForms.nextUpBio' },
    { id: 10, translation: 'SignupProviderForms.nextUpIndexing' },
    { id: 11, translation: 'SignupProviderForms.nextUpCOC' },
    { id: 12, translation: 'SignupProviderForms.nextUpFinish' },
  ];

  const getNextUpTranslation = () => {
    const { translation } = nextUpTranslations.find(t => t.id === step);
    return translation;
  };

  const showUnsavedPrompt = !isEqual(formData, initialFormData);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarBlank />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.main}>
            {renderSignUpStep(step, next, prev, formData, nextFromInfo)}
            <ProgressBar value={completedValue} stepsDone={step} steps={12} hideStepCount />
            <div className={css.nextUpContainer}>
              <div className={css.nextUpLine}>
                <span className={css.nextUpText}>
                  <FormattedMessage id={getNextUpTranslation()} />
                </span>
              </div>
            </div>
            <Modal
              id="SignupProviderAuthModal"
              containerClassName={css.modal}
              isOpen={isAuthOpen}
              onClose={() => setIsAuthOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <AuthenticationPage tab="signup" showUnsavedPrompt={showUnsavedPrompt} />
            </Modal>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

SignupProviderPageComponent.defaultProps = {};

SignupProviderPageComponent.propTypes = {};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SignupProviderPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SignupProviderPageComponent);

export default SignupProviderPage;
